import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import Section from "../layout/section"
import { FixedContainer } from "../layout/container"
import ExternalLink from "../layout/externalLink"

const _ = require(`lodash`)

export const GroupStats = ({ hIndex, pubCount, timesCited }) => (
  <div className="level">
    {pubCount && (
      <div className="level-item has-text-centered">
        <div>
          <p className="heading">Peer-Reviewed Publications</p>
          <p className="title">{pubCount}</p>
        </div>
      </div>
    )}
    {hIndex && (
      <div className="level-item has-text-centered">
        <div>
          <p className="heading">H-Index</p>
          <p className="title">{hIndex}</p>
        </div>
      </div>
    )}
    {timesCited && (
      <div className="level-item has-text-centered">
        <div>
          <p className="heading">Total Times Cited</p>
          <p className="title">{timesCited}</p>
        </div>
      </div>
    )}
  </div>
)

export const GroupMember = ({ member, className }) => {
  const { name, role, profilePic, email, office, url, jobTitle, altName, cv } = member
  const { slug, hIndex, pubCount, timesCited } = member
  const columnClass = className === undefined ? "" : `bio-${className}`

  const { researcherId, ORCID, googleScholar } = member

  const hasResearchLinks = (researcherId !== "" && researcherId !== undefined) ||
    (ORCID !== "" && ORCID !== undefined) || (googleScholar !== "" && googleScholar !== undefined)

  const hasStats = hIndex !== "" || pubCount !== "" || timesCited !== ""
  const hasCv = cv !== undefined && cv !== null && cv !== ""
  
  const img = profilePic.childImageSharp.gatsbyImageData
  console.log(img)

  return (
    <div className={`bio card ${columnClass}`}>
      <div className="card-image">
        <figure className="image round-image">
          <GatsbyImage image={img} alt={name} />
        </figure>
      </div>
      <div className="card-content">
        <div className="media">
          <div className="media-content">
            <p className="title is-4">{name}</p>
            <p className="subtitle is-6">{jobTitle}</p>
          </div>
          {hasCv && (
            <div className="media-right">
              <p>
                <ExternalLink className="button" path={cv} title="CV">
                  <span className="icon is-small">
                    <FontAwesomeIcon icon={["far", "file-pdf"]} title="PDF" />
                  </span>
                  <span>
                    CV
                  </span>
                </ExternalLink>
              </p>
            </div>
          )}
        </div>

        <div className="content">
          <dl className="details">
            {email && (
              <span>
                <dt>Email:</dt>
                <dd>
                  <a href={`mailto:${email}`}>{email}</a>
                </dd>
              </span>
            )}
            {url && (
              <span>
                <dt>URL:</dt>
                <dd>
                  <a href={url}>{url}</a>
                </dd>
              </span>
            )}
            {office && (
              <span>
                <dt>Office:</dt>
                <dd>{office}</dd>
              </span>
            )}
          </dl>
          {hasResearchLinks && (
            <dl className="research">
              {researcherId && (
                <span>
                  <dt>ResearcherID:</dt>
                  <dd>
                    <span id={`rid-${slug}`}>
                      <ExternalLink
                        path={`https://www.webofscience.com/wos/author/rid/${researcherId}`}
                        title="ResearcherID"
                      >
                        {researcherId}
                      </ExternalLink>
                    </span>
                  </dd>
                </span>
              )}
              {ORCID && (
                <span>
                  <dt>ORCID:</dt>
                  <dd>
                    <ExternalLink
                      path={`http://orcid.org/${ORCID}`}
                      title="ORCID"
                    >
                      {ORCID}
                    </ExternalLink>
                  </dd>
                </span>
              )}
              {googleScholar && (
                <span>
                  <dt>Google Scholar:</dt>
                  <dd>
                    <ExternalLink
                      path={`https://scholar.google.com/citations?user=${googleScholar}&hl=en`}
                      title="Google Scholar"
                    >
                      {altName}
                    </ExternalLink>
                  </dd>
                </span>
              )}
            </dl>
          )}
        </div>
      </div>
    </div>
  )
}

export const GroupList = ({ members }) => {
  const groups = _.groupBy(members, "role")
  const leaders = groups["Group Leader"]
  const students = _.sortBy(groups["Graduate Student"], "name")

  // const groupMembers = _.concat(leaders, students)

  return (
    <Section>
      <FixedContainer>
        <div className="columns is-centered">
          <div className="column is-half">
            {leaders && leaders.map((member) => (
              <GroupMember member={member} key={member.id} className="gl" />
            ))}
          </div>
        </div>
        <div className="columns is-centered is-multiline">
          {students && students.map((member) => (
            <div className="column" key={member.id}>
              <GroupMember member={member} className="gs"  />
            </div>
          ))}
        </div>
      </FixedContainer>
    </Section>
  )
}

export default GroupList
