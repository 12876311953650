import React from "react"
import { graphql } from "gatsby"
import urljoin from "url-join"

import ChildPage from "../components/layout/page"
import { GroupList } from "../components/people/Group"

import config from "../../content/data/SiteConfig"

const PeoplePage = ({ data }) => {
  const groupEdges = data.allMemberProfile.edges

  const headerImage = data.header.publicURL

  const parallax = {
    image: headerImage,
    strength: 400
  }

  const members =  []
  const peopleSchema = []
  groupEdges.forEach(edge => {
    const { node } = edge

    members.push(node)

    const { familyName, givenName, profilePic, email, phone, url, honorificPrefix, sameAs, jobTitle, gender} = node
    const { publicURL } = profilePic
    const imageUrl = urljoin(config.site.url, config.site.prefix, publicURL)

    const memberSchema = {
      "@context": "http://schema.org",
      "@type": "Person",
      affiliation: "University of Rochester",
      email,
      telephone: phone,
      url,
      honorificPrefix,
      sameAs,
      jobTitle,
      familyName,
      givenName,
      gender,
      image: imageUrl
    }

    peopleSchema.push(memberSchema)
  })

  const breadcrumbs = [
    { "key": "home", "title": "Home", "path": "/" },
    { "key": "people", "title": "People", "path": "/people" }
  ]

  return (
    <ChildPage 
      title="People" 
      subtitle="Current Group Members"
      breadcrumbs={breadcrumbs}
      structuredData={peopleSchema}
      parallax={parallax}
    >
      <GroupList members={members} />
    </ChildPage>
  )
}

export default PeoplePage

export const pageQuery = graphql`query PeoplePage {
  header: file(relativePath: {eq: "campus_banner.jpg"}) {
    publicURL
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  allMemberProfile {
    edges {
      node {
        id
        slug
        hIndex
        timesCited
        pubCount
        email
        name
        office
        role
        altName
        ORCID
        phone
        googleScholar
        researcherId
        researcherInternalId
        url
        honorificPrefix
        sameAs
        gender
        jobTitle
        familyName
        givenName
        cv
        profilePic {
          publicURL
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              height: 800
              aspectRatio: 1.0
              placeholder: BLURRED
            )
          }
        }
      }
    }
  }
}
`